<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$route.params.id ? $router.push({ name: 'contract-show', params: {id: $route.params.id} }) : $router.push({ name: 'contracts'})"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{$route.params.id ? $t('salary.contracts.edit-new-contract') :  $t('salary.contracts.create-new-contract') }}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$route.params.id ? $router.push({ name: 'contract-show', params: {id: $route.params.id} }) : $router.push({ name: 'contracts'})"><icon-close class="icon-close" /></button>
				</div>
			</div>
            <choose-people v-if="!$route.params.id" :choose_people="choose_people" :users="users" :validator="$v.choose_people"/>
            <personal-data v-if="!$route.params.id" :personal_data="personal_data" :optionsCountries="optionsCountries" :optionsCounties="optionsCounties" :optionsTowns="optionsTowns" :optionsDocumentType="optionsDocumentType" :validator="$v.personal_data"/>
            <contract-data :contract_data="contract_data" :contractTypes="contract_types" :validator="$v.contract_data"/>
            <contract-details v-if="!$route.params.id" :contract_details="contract_details" :optionsBank="optionsBank" :optionsHealthInsurance="optionsHealthInsurance" :validator="$v.contract_details"/>
            <duration-contract v-if="!$route.params.id" :duration="duration" :contractTypes="contract_types_period" :validator="$v.duration"/>
            <salary-contract v-if="!$route.params.id" :salary="salary" :calculTypes="calcul_types" :validator="$v.salary"/>
            <work-program v-if="!$route.params.id" :work_program="work_program" :norms="norms" :normsType="norms_type" :validator="$v.work_program"/>
            <contract-fiscality v-if="!$route.params.id" :fiscality="fiscality" :incomeOptions="incomeOptions" :workConditionsOptions="workConditionsOptions" :taxExemptOptions="taxExemptOptions" :disabilityOptions="disabilityOptions" :invalidityOptions="invalidityOptions" :validator="$v.fiscality"/>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$route.params.id ? $router.push({ name: 'contract-show', params: {id: $route.params.id} }) : $router.push({ name: 'contracts'})"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
</template>

<script>
	import IconArrowBack from '../../../Icons/ArrowLong'
	import IconClose from '../../../Icons/Close'
    import ChoosePeople from './FormComponents/ChoosePeople'
    import PersonalData from './FormComponents/PersonalData'
	import ContractData from './FormComponents/ContractData'
    import ContractDetails from './FormComponents/ContractDetails'
    import DurationContract from './FormComponents/Duration'
    import SalaryContract from './FormComponents/Salary'
    import WorkProgram from './FormComponents/WorkProgram'
    import ContractFiscality from './FormComponents/Fiscality'
    import ContractModification from './FormComponents/ModificationContract'
	import { required, requiredIf, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

    const validCNP = (value) => !helpers.req(value) || checkCNP(value)

    function checkCNP( p_cnp ) {
        var i=0 , year=0 , hashResult=0 , cnp=[] , hashTable=[2,7,9,1,4,6,3,5,8,2,7,9];
        if( p_cnp.length !== 13 ) { return false; }
        for( i=0 ; i<13 ; i++ ) {
            cnp[i] = parseInt( p_cnp.charAt(i) , 10 );
            if( isNaN( cnp[i] ) ) { return false; }
            if( i < 12 ) { hashResult = hashResult + ( cnp[i] * hashTable[i] ); }
        }
        hashResult = hashResult % 11;
        if( hashResult === 10 ) { hashResult = 1; }
        year = (cnp[1]*10)+cnp[2];
        switch( cnp[0] ) {
            case 1  : case 2 : { year += 1900; } break;
            case 3  : case 4 : { year += 1800; } break;
            case 5  : case 6 : { year += 2000; } break;
            case 7  : case 8 : case 9 : { year += 2000; if( year > ( parseInt( new Date().getYear() , 10 ) - 14 ) ) { year -= 100; } } break;
            default : { return false; }
        }
        if( year < 1800 || year > 2099 ) { return false; }
        return ( cnp[12] === hashResult );
        
    }

	export default {
		components: {
			IconArrowBack,
			IconClose,
            ChoosePeople,
            PersonalData,
			ContractData,
            ContractDetails,
            DurationContract,
            SalaryContract,
            WorkProgram,
            ContractFiscality,
            ContractModification
		},
		data() {
			return {
				loaded: false,
				error_message: '',
                users: [],
                choose_people:{
                    selectedUser: '',
                },
                optionsCountries: [],
                optionsCounties: [],
                optionsTowns: [],
                optionsDocumentType: [],
                personal_data: {
                    cnp: '',
                    selectedCitizenship: '',
                    selectedCountry: '',
                    selectedCounty: '',
                    county: '',
                    selectedTown: '',
                    town: '',
                    street_and_number: '',
                    selectDocumentType: '',
                    series: '',
                    number: '',
                    issued_by: '',
                    issue_date: {}
                },
				contract: {},
				contract_data: {
                    selectedContractType: '',
                    contract_number: '',
					mark: '',
                    date_contract: {},
                    first_day_of_activity: {},
                    last_day_of_activity: {},
                    contract_end_date: {},
                    contract_observations: ''
				},
                contract_types: [],
                duration: {
                    selectedContractType: '',
                    months: '',
                    final_date: {},
                    contract_observations: ''
                },
                optionsBank: [],
                optionsHealthInsurance: [],
                contract_details: {
                    selectedBank: '',
                    iban_code: '',
                    selectedHealthInsurance: '',
                    selectedCodeCor: ''
                },
                contract_types_period: [],
                salary: {
                    calcul_type: {id: 1,name: "lunar"},
                    base_salary: '',
                    net_salary: '',
                    hourly_rate: '',
                    advance: ''
                },
                calcul_types: [],
                work_program: {
                    norm: 8,
                    norm_type: {id: 1 ,name: "ore/zi"},
                    daily_hours: '',
                    vacation_days: ''
                },
                norms: [],
                norms_type: [],
                fiscality: {
                    selectedIncomeType: '',
                    selectedWorkCondition: '',
                    basic_function: 1,
                    deduction_start_date: {},
                    tax_exempt: 0,
                    selectTaxExptionType: '',
                    retired: 0,
                    retirement_start_date: {},
                    selectDegreeOfDisability: '',
                    start_date_of_disability: {},
                    end_date_of_disability: {},
                    number_disability_document: '',
                    selectInvalidityType: '',
                    start_date_of_invalidity: {},
                    end_date_of_invalidity: {},
                },
                incomeOptions: [],
                workConditionsOptions: [],
                taxExemptOptions: [],
                disabilityOptions: [],
                invalidityOptions:[],
                modification: {
                    selectedModificationContract: '',
                    active_from: {},
                    document_number: '',
                    selectedContractModificationReason: '',
                    observations: '',
                },
                optionsModificationContract: [],
                optionsContractModificationReason: []
			}
		},
		validations: {
            choose_people: {
                selectedUser: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
            },
            personal_data: {
                cnp: { 
                    required: requiredIf(function () {
                        return validCNP && !this.$route.params.id
                    }),
                    minLength: minLength(13),
                    maxLength: maxLength(13) 
                },
            },
			contract_data: {
                contract_number: { 
                    required: requiredIf(function () {
                        return !this.$route.params.modification_id
                    }) },
                selectedContractType: { 
                    required: requiredIf(function () {
                        return !this.$route.params.modification_id
                    }) },
                date_contract: { 
                    required: requiredIf(function () {
                        return !this.$route.params.modification_id
                    }) },
                first_day_of_activity: { 
                    required: requiredIf(function () {
                        return !this.$route.params.modification_id
                    }) }
			},
            contract_details: {
                selectedHealthInsurance: { 
                    required: requiredIf(function () {
                        return !this.$route.params.id
                    }) },
                iban_code: {
                    minLength: minLength(24),
                    maxLength: maxLength(24) 
                }
            },
            duration: {
                selectedContractType: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                months: { required: requiredIf(function () {
                    return !this.$route.params.id && this.duration.selectedContractType.id == 2
                }) },
                final_date:  { required: requiredIf(function () {
                    return !this.$route.params.id && this.duration.selectedContractType.id == 2
                }) },
            },
            salary: {
                base_salary: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) }
            },
            work_program: {
                norm: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                norm_type: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                daily_hours: { required: requiredIf(function () {
                    return !this.$route.params.id
                })}
            },
            fiscality: {
                selectedIncomeType: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                selectedWorkCondition: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                basic_function: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                deduction_start_date: {required: requiredIf(function () {
                    return !this.$route.params.id && this.fiscality.basic_function == 1
                }) },
                tax_exempt: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                selectTaxExptionType: { required: requiredIf(function () {
                    return !this.$route.params.id && this.fiscality.tax_exempt == 1
                }) },
                retired: { required: requiredIf(function () {
                    return !this.$route.params.id
                }) },
                retirement_start_date: { required: requiredIf(function () {
                    return !this.$route.params.id && this.fiscality.retired == 1
                }) }
            },
		},
		async mounted(){
            await this.getCountries();
            await this.getSelectData()
            await this.getUsers()
			
            if(this.$route.params.id){
                await this.getContractData();
			}
            this.$root.$on('userDataSelect', () => {
                this.clearData()
                this.getUserData()
			})
            this.$root.$on('countySelect', (county_id) => {
                this.getTowns(county_id)
			})
		},
        beforeDestroy() {
            this.$root.$off('userDataSelect')
            this.$root.$off('countySelect')
        },
		methods: {
            async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountries = Object.entries(data.data).map((el,index) =>{
						return{id: el[0], name: el[1]}
					})
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
            async getTowns(county_id){
                this.personal_data.selectedTown = ''
                await axios.get(`/towns?county_id=${county_id}`).then((data) => {
                    this.optionsTowns = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
            },
            async getUserData() {
                await axios.get(`/users/${this.choose_people.selectedUser.slug}?only_details=true`)
				.then(async ({data}) => {
                    if(data.data.issue_date){
                        this.personal_data.issue_date = {
                            selectedDate: data.data.issue_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    this.personal_data.cnp = data.data.cnp
                    this.personal_data.selectedCitizenship = data.data.country ? this.optionsCountries.find(el => el.name == data.data.country) : ''
                    this.personal_data.selectedCountry = data.data.country ? this.optionsCountries.find(el => el.name == data.data.country) : ''
                    if(data.data.country == 'Romania') {
                        if(data.data.county) {
                            this.personal_data.selectedCounty = this.optionsCounties.find(el => el.name.toLowerCase() == data.data.county.toLowerCase())
                            await this.getTowns(this.personal_data.selectedCounty.id)
                        }
                        if(data.data.town) {
                            this.personal_data.selectedTown = this.optionsTowns.find(el => el.name == data.data.town)
                        }
                    } else {
                        this.personal_data.county = data.data.county
                        this.personal_data.town = data.data.town
                    }
                    this.personal_data.street_and_number = data.data.street_and_number
                    this.personal_data.selectDocumentType = data.data.user_document_type_id ? this.optionsDocumentType.find(el => el.id == data.data.user_document_type_id) : ''
                    this.personal_data.series = data.data.series
                    this.personal_data.number = data.data.number
                    this.personal_data.issued_by = data.data.issued_by
                    this.work_program.vacation_days = data.data.vacation_days ? data.data.vacation_days : ''
                    if(data.data.date_of_employment){
                        this.contract_data.date_contract = {
                            selectedDate: data.data.date_of_employment,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    
					// this.userData = data.data
					// this.procedures = data.data.procedures
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
            async getSelectData(){
                await axios.get(`${this.$auth.user().instance.id}/contract-form-noms`)
				.then(({data}) => {
                    this.contract_types = data.data.contract_types
                    this.contract_types_period = data.data.contract_period_types
                    this.calcul_types = data.data.contract_salary_calculation_types
                    this.norms = data.data.norms
                    this.norms_type = data.data.working_norm_types
                    this.incomeOptions = data.data.income_types
                    if(!this.fiscality.selectedIncomeType) {
                        this.fiscality.selectedIncomeType = this.incomeOptions.find(el => el.id == 1)
                    }
                    this.workConditionsOptions = data.data.work_condition_types
                    if(!this.fiscality.selectedWorkCondition) {
                        this.fiscality.selectedWorkCondition = this.workConditionsOptions.find(el => el.id == 1)
                    }
                    this.taxExemptOptions = data.data.tax_exemption_types
                    this.disabilityOptions = data.data.disability_degree_types
                    this.invalidityOptions = data.data.invalidity_degree_types
                    this.optionsModificationContract = data.data.contract_modification_types
                    this.optionsContractModificationReason = data.data.contract_modification_reasons
                    this.optionsCounties = data.data.counties
                    this.optionsDocumentType = data.data.user_document_types
                    this.optionsBank = data.data.banks
                    this.optionsHealthInsurance = data.data.national_health_insurance_agencies
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
                    setTimeout(() => {
                        this.loaded = true
                        setTimeout(() => {
                            $('.opacity-page').addClass('show')
                        }, 0)
                    }, 0)
                })
            },
            async getUsers(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true}})
				.then(({data}) => {
					this.users = data.data.users
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
            getContractData(){
                axios.get(`/contracts/${this.$route.params.id}/edit`).then(({data}) => {
                    this.choose_people.selectedUser = this.users.find(el => el.id == data.data.user_id)
                    this.contract_data.selectedContractType =  this.contract_types.find(el => el.name == data.data.contract_type)
                    this.contract_data.contract_number = data.data.contract_number
                    this.contract_data.mark = data.data.marca ? data.data.marca : ''
                    this.contract_data.contract_observations = data.data.contract_observations
                    if(data.data.contract_date){
                        this.contract_data.date_contract = {
                            selectedDate: data.data.contract_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    if(data.data.first_day_of_activity){
                        this.contract_data.first_day_of_activity = {
                            selectedDate: data.data.first_day_of_activity,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    if(data.data.last_day_of_activity){
                        this.contract_data.last_day_of_activity = {
                            selectedDate: data.data.last_day_of_activity,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    if(data.data.contract_end_date){
                        this.contract_data.contract_end_date = {
                            selectedDate: data.data.contract_end_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                })
            },
            clearData() {
                this.personal_data.cnp = ''
                this.personal_data.selectedCitizenship = ''
                this.personal_data.selectedCountry = ''
                this.personal_data.selectedCounty = ''
                this.personal_data.county = ''
                this.personal_data.selectedTown = ''
                this.personal_data.town = ''
                this.personal_data.street_and_number = ''
                this.personal_data.selectDocumentType = ''
                this.personal_data.series = ''
                this.personal_data.number = ''
                this.personal_data.issued_by = ''
                this.personal_data.issue_date = {}
            },
			saveAction(type) {
                var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
                    let objData = {contract_periods:{}, contract_details: {}, contract_salaries: {}, contract_work_programs: {}, contract_fiscalities: {}}

                    if(this.$route.params.id){
                        // contract data
                        objData.contract_start_date = Object.keys(this.contract_data.date_contract).length ? this.contract_data.date_contract.selectedDate : ''
                        objData.first_day_of_activity = Object.keys(this.contract_data.first_day_of_activity).length ? this.contract_data.first_day_of_activity.selectedDate : ''
                        objData.last_day_of_activity = Object.keys(this.contract_data.last_day_of_activity).length ? this.contract_data.last_day_of_activity.selectedDate : ''
                        objData.contract_end_date = Object.keys(this.contract_data.contract_end_date).length ? this.contract_data.contract_end_date.selectedDate : ''

                        objData.user_id = this.selectedUser ? this.selectedUser.id : ''
                        objData.contract_type_id = this.contract_data.selectedContractType ? this.contract_data.selectedContractType.id : ''
                        objData.contract_number = this.contract_data.contract_number
                        objData.marca = this.contract_data.mark
                        objData.contract_observations = this.contract_data.contract_observations
                    } else {
                        // personal data
                        objData.issue_date = Object.keys(this.personal_data.issue_date).length ? this.personal_data.issue_date.selectedDate : ''
                        objData.cnp = this.personal_data.cnp
                        objData.citizenship = this.personal_data.selectedCitizenship ? this.personal_data.selectedCitizenship.name: ''
                        objData.country = this.personal_data.selectedCountry ? this.personal_data.selectedCountry.name: ''
                        if(this.personal_data.selectedCountry.name == 'Romania') {
                            if(this.personal_data.selectedCountry) {
                                objData.county = this.personal_data.selectedCounty ? this.personal_data.selectedCounty.name: ''
                            }
                            if(this.personal_data.selectedTown) {
                                objData.town = this.personal_data.selectedTown ? this.personal_data.selectedTown.name: ''
                            }
                        } else {
                            objData.county = this.personal_data.county
                            objData.town = this.personal_data.town
                        }
                        objData.street_and_number = this.personal_data.street_and_number
                        objData.user_document_type_id = this.personal_data.selectDocumentType ? this.personal_data.selectDocumentType.id: ''
                        objData.series = this.personal_data.series
                        objData.number = this.personal_data.number
                        objData.issued_by = this.personal_data.issued_by

                        // contract data
                        objData.contract_start_date = Object.keys(this.contract_data.date_contract).length ? this.contract_data.date_contract.selectedDate : ''
                        objData.first_day_of_activity = Object.keys(this.contract_data.first_day_of_activity).length ? this.contract_data.first_day_of_activity.selectedDate : ''
                        objData.last_day_of_activity = Object.keys(this.contract_data.last_day_of_activity).length ? this.contract_data.last_day_of_activity.selectedDate : ''
                        objData.contract_end_date = Object.keys(this.contract_data.contract_end_date).length ? this.contract_data.contract_end_date.selectedDate : ''

                        objData.user_id = this.choose_people.selectedUser ? this.choose_people.selectedUser.id : ''
                        objData.contract_type_id = this.contract_data.selectedContractType ? this.contract_data.selectedContractType.id : ''
                        objData.contract_number = this.contract_data.contract_number
                        objData.marca = this.contract_data.mark
                        objData.contract_observations = this.contract_data.contract_observations

                        // Contract details
                        objData.contract_details.bank_id = this.contract_details.selectedBank ? this.contract_details.selectedBank.id : ''
                        objData.contract_details.iban_code = this.contract_details.iban_code
                        objData.contract_details.national_health_insurance_agency_id = this.contract_details.selectedHealthInsurance ? this.contract_details.selectedHealthInsurance.id : ''
                        objData.contract_details.code_cor = this.contract_details.selectedCodeCor ? this.contract_details.selectedCodeCor.code : ''

                        // duration
                        objData.contract_periods.expiration_date = Object.keys(this.duration.final_date).length ? this.duration.final_date.selectedDate : ''

                        objData.contract_periods.contract_period_type_id = this.duration.selectedContractType ? this.duration.selectedContractType.id : '' 
                        objData.contract_periods.months = this.duration.months
                        objData.contract_periods.observations = this.duration.contract_observations 
                        
                        // salary
                        objData.contract_salaries.contract_salary_calculation_type_id = this.salary.calcul_type ? this.salary.calcul_type.id : ''
                        objData.contract_salaries.base_salary = this.salary.base_salary
                        objData.contract_salaries.net_salary = this.salary.net_salary
                        objData.contract_salaries.hourly_wage = this.salary.hourly_rate
                        //  salary: { advance: ''}

                        // work_program
                        objData.contract_work_programs.norm = this.work_program.norm
                        objData.contract_work_programs.working_norm_type_id = this.work_program.norm_type ? this.work_program.norm_type.id : ''
                        objData.contract_work_programs.hours_per_day = this.work_program.daily_hours
                        objData.contract_work_programs.annual_leave_days = this.work_program.vacation_days

                        //fiscality
                        objData.contract_fiscalities.deduction_start_date = Object.keys(this.fiscality.deduction_start_date).length ? this.fiscality.deduction_start_date.selectedDate : ''
                        objData.contract_fiscalities.retirement_start_date = Object.keys(this.fiscality.retirement_start_date).length ? this.fiscality.retirement_start_date.selectedDate : ''
                        objData.contract_fiscalities.disability_start_date = Object.keys(this.fiscality.start_date_of_disability).length ? this.fiscality.start_date_of_disability.selectedDate : ''
                        objData.contract_fiscalities.disability_end_date = Object.keys(this.fiscality.end_date_of_disability).length ? this.fiscality.end_date_of_disability.selectedDate : ''
                        objData.contract_fiscalities.invalidity_start_date = Object.keys(this.fiscality.start_date_of_invalidity).length ? this.fiscality.start_date_of_invalidity.selectedDate : ''
                        objData.contract_fiscalities.invalidity_end_date = Object.keys(this.fiscality.end_date_of_invalidity).length ? this.fiscality.end_date_of_invalidity.selectedDate : ''
                        
                        objData.contract_fiscalities.income_type_id = this.fiscality.selectedIncomeType ? this.fiscality.selectedIncomeType.id : ''
                        objData.contract_fiscalities.work_condition_type_id = this.fiscality.selectedWorkCondition ? this.fiscality.selectedWorkCondition.id : ''
                        objData.contract_fiscalities.basic_function = this.fiscality.basic_function
                        objData.contract_fiscalities.tax_exemption = this.fiscality.tax_exempt
                        objData.contract_fiscalities.retired = this.fiscality.retired
                        objData.contract_fiscalities.tax_exemption_type_id = this.fiscality.selectTaxExptionType ? this.fiscality.selectTaxExptionType.id : ''
                        objData.contract_fiscalities.disability_degree_type_id = this.fiscality.selectDegreeOfDisability ? this.fiscality.selectDegreeOfDisability.id : ''
                        objData.contract_fiscalities.disability_document_number = this.fiscality.number_disability_document
                        objData.contract_fiscalities.invalidity_degree_type_id = this.fiscality.selectInvalidityType ? this.fiscality.selectInvalidityType.id : ''
                    }
                    if(type == 'create'){
						this.createContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

                }else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/contracts/store', objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
                        this.$router.push({ name: 'contracts' })
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.email) {
								this.error_email = true;
							} else if(error.response.data.errors.business_email) {
								this.error_business_email = true;
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
                            if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
            updateContract(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/contracts/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
                        this.$router.push({ name: 'contracts' })
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.email) {
								this.error_email = true;
							} else if(error.response.data.errors.business_email) {
								this.error_business_email = true;
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
                            if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
		}
	}
</script>

<style lang="scss">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>